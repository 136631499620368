import React from "react";
import { IIntroductionComponent } from "../libs/types";
import { PortableText } from "@portabletext/react";

export const Introduction = ({
	introContent,
	clientContent,
	serviceContent,
}: IIntroductionComponent) => {
	const { tagline, mailto, instagramLink, instagramHandle, contactText } =
		introContent;

	const { clientList } = clientContent;

	const { servicesList } = serviceContent;

	return (
		<div className="module fade-in">
			<div className="text-group">
				<p>{tagline}</p>
				<a href={`mailto:${mailto}`}>{contactText}</a>
			</div>

			<div className="text-group">
				<a href={instagramLink} target="blank" className="text-gray">
					{instagramHandle}
				</a>
			</div>
			<div className="text-group">
				<p className="italic">Services</p>
				<PortableText value={servicesList} />
			</div>

			<div className="text-group">
				<p className="italic">Clients</p>
				<PortableText value={clientList} />
			</div>
		</div>
	);
};
