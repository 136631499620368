import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import sanityClient from "./client.ts";
import { IClientData, IIntroduction, ISelectWork, IServices } from "./types.ts";
import imageUrlBuilder from "@sanity/image-url";

export function getImgUrl(source: SanityImageSource) {
	return imageUrlBuilder(sanityClient).image(source).url();
}

export async function getAllWorks(): Promise<[] | ISelectWork[]> {
	try {
		const data: ISelectWork[] = await sanityClient.fetch(
			`*[_type == "selectWork" && publish == true]|order(orderRank)`
		);
		return data;
	} catch (message) {
		console.error(message);
		return [];
	}
}

export async function getIntroductionData(): Promise<
	undefined | IIntroduction
> {
	try {
		const data: IIntroduction = await sanityClient.fetch(
			`*[_type == "introduction"][0]`
		);
		return data;
	} catch (message) {
		console.error(message);
		return;
	}
}

export async function getAllClientData() {
	try {
		const data: IClientData = await sanityClient.fetch(
			`*[_type == "clients"][0]`
		);
		return data;
	} catch (message) {
		console.error(message);
		return;
	}
}

export async function getAllServiceData() {
	try {
		const data: IServices = await sanityClient.fetch(
			`*[_type == "services"][0]`
		);
		return data;
	} catch (message) {
		console.error(message);
		return;
	}
}
