import { ISelectWork, ISelectWorkComponent } from "../libs/types";
import { getImgUrl } from "../libs/sanity.ts";
import { PortableText } from "@portabletext/react";
import { portableTextComponents } from "./PortableTextComponents.tsx";

export const SelectWorks = ({ works }: ISelectWorkComponent) => {
	return (
		<div className="module fade-in select-work">
			<p className="italic">Select Work</p>
			{works.map((data) => (
				<Row work={data} key={data._id} />
			))}
		</div>
	);
};

const Row = ({ work }: { work: ISelectWork }) => {
	const { workTitle, mediaList, workDescription } = work;

	return (
		<div className="split">
			<div className="left">
				<p>{workTitle}</p>
				<PortableText
					value={workDescription}
					components={portableTextComponents}
				/>
			</div>
			<div className="right">
				{mediaList.map((asset) => (
					<div
						key={asset._key}
						className="img"
						style={{
							backgroundImage: `url(${getImgUrl(asset.asset)})`,
						}}
					></div>
				))}
			</div>
		</div>
	);
};
