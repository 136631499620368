import { PortableTextReactComponents } from "@portabletext/react";

export const portableTextComponents: Partial<PortableTextReactComponents> = {
	marks: {
		link: ({ children, value }) => {
			const rel =
				!!value.href && !value.href.startsWith("/")
					? "noreferrer noopener"
					: undefined;

			return (
				// eslint-disable-next-line react/jsx-no-target-blank
				<a
					href={value.href}
					className="body link"
					rel={rel}
					target="_blank"
				>
					{children}
				</a>
			);
		},
	},
};
