export const FixedLogo = () => {
	return (
		<div className="bottom">
			<h1>
				<a href="http://www.nice-handwriting.com">
					<img
						src="/assets/nice-handwriting.svg"
						alt="Nice Handwriting."
						className="logo"
					/>
				</a>
			</h1>
			<p className="caps">A digital design and strategy studio.</p>
		</div>
	);
};
