import React, { useEffect, useState } from "react";
import {
	getAllWorks,
	getIntroductionData,
	getAllClientData,
	getAllServiceData,
} from "./libs/sanity.ts";
import {
	IIntroduction,
	ISelectWork,
	IClientData,
	IServices,
} from "./libs/types.ts";
import { Introduction } from "./components/introServicesAndClients.tsx";
import { SelectWorks } from "./components/SelectWorks.tsx";
import { FixedLogo } from "./components/FixedLogo.tsx";
import { Footer } from "./components/Footer.tsx";

export function App() {
	const [works, setWorks] = useState<ISelectWork[]>([]);
	const [introData, setIntroData] = useState<IIntroduction>();
	const [clientData, setClientData] = useState<IClientData>();
	const [serviceData, setServiceData] = useState<IServices>();

	useEffect(() => {
		async function getWorks() {
			return setWorks(await getAllWorks());
		}

		async function getIntroData() {
			return setIntroData(await getIntroductionData());
		}

		async function getClientData() {
			return setClientData(await getAllClientData());
		}

		async function getServiceData() {
			return setServiceData(await getAllServiceData());
		}

		if (!works.length) getWorks();

		if (!introData) getIntroData();

		if (!clientData) getClientData();

		if (!serviceData) getServiceData();
		// eslint-disable-next-line
	}, []);

	return (
		<React.Fragment>
			{introData && clientData && serviceData ? (
				<Introduction
					introContent={introData}
					clientContent={clientData}
					serviceContent={serviceData}
				/>
			) : null}

			<SelectWorks works={works} />
			<FixedLogo />
			<Footer />
		</React.Fragment>
	);
}
